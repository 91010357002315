<template>
  <div class="ma-10">
    <v-card outlined class="mb-5">
      <!-- Expansion -->
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>

          <!-- Body -->
          <v-expansion-panel-content>
            <v-autocomplete
              v-model="selectFilterAffiliation"
              :items="filterAffiliations"
              label="所属"
              clearable
              dense
              item-text="name"
              item-value="code"
            >
            </v-autocomplete>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Actions -->
      <v-card-actions class="ma-2">
        <v-spacer />
        <Btn color="info" icon="mdi-magnify" @click="select">検索</Btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-5">
      <v-card-actions class="ma-2">
        <Btn
          icon="sort-clock-ascending-outline"
          color="info"
          text
          :disabled="$isEmpty(items.length)"
          @click="sortOrder"
        >
          開始時間で並び替え
        </Btn>
        <v-spacer />
        <Btn
          icon="mdi-lead-pencil"
          color="success"
          :disabled="$isEmpty(items.length)"
          @click="updateOrder"
        >
          更新
        </Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <table>
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
            draggable
            @dragstart="dragList($event, index)"
            @drop="dropList($event, index)"
            @dragover.prevent
            @dragenter.prevent
          >
            <td>{{ item.abbreviation }}</td>
            <td>{{ item.startTimeDisplay }}</td>
            <td>{{ item.endTimeDisplay }}</td>
            <td>{{ item.patternOrder }}</td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

export default {
  name: "ShiftPatternOrder",
  mixins: [Common, ShowDialogs, Api],
  props: {},
  data() {
    return {
      panel: null,
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      filterAffiliations: [],
      selectFilterAffiliation: "",
      display: false,
      searchedAffiliation: {}
    };
  },
  methods: {
    dragList(event, dragIndex) {
      console.log(dragIndex);
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.setData("drag-index", dragIndex);
    },
    dropList(event, dropIndex) {
      const dragIndex = event.dataTransfer.getData("drag-index");
      const deleteList = this.items.splice(dragIndex, 1);
      this.items.splice(dropIndex, 0, deleteList[0]);
      this.items.map((item, index) => {
        item.patternOrder = index + 1;
      });
    },
    async sortOrder() {
      console.log("sortOrder");
      this.items.sort((a, b) => {
        let sa =
          a.startTimeDisplay.split(":")[0] == "00"
            ? "24"
            : a.startTimeDisplay.split(":")[0];
        sa += a.startTimeDisplay.split(":")[1];
        let sb =
          b.startTimeDisplay.split(":")[0] == "00"
            ? "24"
            : b.startTimeDisplay.split(":")[0];
        sb += b.startTimeDisplay.split(":")[1];
        let ea =
          a.endTimeDisplay.split(":")[0] == "00"
            ? "24"
            : a.endTimeDisplay.split(":")[0];
        ea += a.endTimeDisplay.split(":")[1];
        let eb =
          b.endTimeDisplay.split(":")[0] == "00"
            ? "24"
            : b.endTimeDisplay.split(":")[0];
        eb += b.endTimeDisplay.split(":")[1];

        if (sa < sb) return -1;
        if (sa > sb) return 1;
        if (ea < eb) return -1;
        if (ea > eb) return 1;
      });
      this.items.map((item, index) => {
        item.patternOrder = index + 1;
      });
    },
    async updateOrder() {
      console.log("updateOrder");
      const records = [];
      this.items.forEach(item => {
        const param = {
          shiftPatternId: item.shiftPatternId,
          patternOrder: item.patternOrder
        };
        records.push(param);
      });
      this.$loading();

      try {
        await this.$put(this.Paths.shiftPatternOrder, records);
        await this.$info("更新しました。");
        await this.selectRecord(this.searchedAffiliation.code);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getdata(path) {
      this.$loading();
      try {
        return await this.$get(path);
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async selectRecord(affiliationid) {
      console.log("selectRecord", affiliationid);

      const param = { shozoku: affiliationid };
      const encode = encodeURI(JSON.stringify(param));

      const data = await this.$get(
        this.Paths.shiftPatternOrder,
        "query=" + encode
      );
      await this.setdata(data);

      if (this.selectFilterAffiliation) {
        this.display = true;
        const filter = this.filterAffiliations.filter(
          e => e.code === this.selectFilterAffiliation
        );
        this.searchedAffiliation = filter[0];
      } else {
        this.display = false;
        this.searchedAffiliation = {};
      }
      console.log(this.searchedAffiliation);
    },
    async select() {
      console.log("select");
      this.$loading();
      try {
        if (
          this.selectFilterAffiliation !== null &&
          this.selectFilterAffiliation !== ""
        ) {
          await this.selectRecord(this.selectFilterAffiliation);
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async update() {
      console.log("==update");
      this.$loading();
      try {
        this.filterAffiliations = await this.$get(this.Paths.shozokuFilter);
        const data = await this.$get(this.Paths.shiftPatternOrder);
        await this.setdata(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async setdata(data) {
      console.log("setdata");
      this.selected = [];
      this.headers = data.headers;
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;
    }
  },
  created() {
    console.log("==created");
    this.display = false;
    this.update();
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

table th {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  width: 5%;
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

table td {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-top: none;
  width: 5%;
  padding: 10px 5px;
  font-size: 0.875rem;
}
</style>
